import Templates from './layout/Templates';
import Toolbar from './layout/Toolbar';
import './App.css';
import './css/fonts.css';
import { useEffect } from "react";
import { ThemeProvider } from '@rescui/ui-contexts';
import { API } from './api.js';
import {CHANNELS, GENERAL_TYPE, PRODUCTS} from './data/products.js';
import {checkImageLoaded, exportLayouts, findBgUrl, isEmptyObj, updateTextareas} from "./libs/tools";
import { DEFAULT_BACKGROUND_DATA} from "./data/backgrounds";
import {useLocation} from "react-router-dom";
import {useEdits} from './contexts/edits.js';
import {useImages} from "./contexts/images";
import {useSetting} from "./contexts/setting";
import {templates} from "./data/templates";
import {BACKGROUNDS, GENERAL_BG, TAGS} from "./data/constants";


const App = () => {
  const { editNames, loadTitles, editProductNames, editDates, editPositions, editFontVariation, dates, names, positions, titles, productNames, fontVariation} = useEdits();
  const { bgX, bgY, bgScale, uploadX, uploadY, uploadScale, editTransform, rerenderBg, customBg, editBgUrl, editCustomBg, images, loadImages, editUploadTransform, allBgUrls, editBgUrl2x } = useImages();
  const {editProduct, editType, editChannel, editBgOption, editPalettes, bgOption, product, channel, type, layoutOption, editLayoutOption, editIconOption, addVisible, editTag, editHash } = useSetting();
  const location = useLocation();
  const theme = 'dark';

  // TODO refactor
  const loadNormBg = () => {
    if (allBgUrls.length > 0) {
      const src = findBgUrl(allBgUrls, +bgOption, `/${product.value}.png`);
      const src2x = findBgUrl(allBgUrls, +bgOption, `/${product.value}@2x.png`);
      editBgUrl(src);
      editBgUrl2x(src2x);
      editTag(TAGS[2]);

      if (!src) {
        const outdated = findBgUrl(allBgUrls, +bgOption, `/${product.value}-outdated.png`);
        let outdated2x = findBgUrl(allBgUrls, +bgOption, `/${product.value}@2x-outdated.png`);
        editBgUrl(outdated);
        editBgUrl2x(outdated2x);

        //  TODO check and fix this
        const isOutdated = PRODUCTS.find(elem =>  elem.value === product.value).tag === "outdated";
        if (isOutdated) {
          editTag(TAGS[1]);
        } else {
          editTag(TAGS[2]);
        }
        // editTag(TAGS[1]);

        if (!outdated) {
          editTag(TAGS[0]);
        }
      }
    }
  }




  useEffect(() => {
    if (bgOption === "custom") {
      editBgUrl(customBg);
    } else {
      loadNormBg()
    }
  }, [product, type, channel, bgOption, customBg, allBgUrls]);



  useEffect( () => {
    const fetchParams = async () => {
      if (window.location.hash) {
        const h = window.location.hash.slice(1)
        const params = await API.getParams(h);

        if (params) {
          const { product, fontVariation, channel, type, names, titles, dates, positions, productNames, bgScale, bgX, bgY, bgOption, customBg, layoutOption, iconOption, images, uploadY, uploadX, uploadScale  }  = params;

          editHash(h);
          if (product) editProduct(product);
          if (channel) editChannel(channel);
          if (type) editType(type);
          if (bgOption) editBgOption(+bgOption);
          if (bgOption) editLayoutOption(+layoutOption);
          if (images) loadImages(images);
          if (iconOption) editIconOption(+iconOption);
          if (customBg) editCustomBg(customBg);
          if (!isEmptyObj(names)) editNames(names);
          if (titles) loadTitles(titles);
          if (productNames) editProductNames(productNames);
          if (dates) editDates(dates);
          if (positions) editPositions(positions);
          if (fontVariation) editFontVariation(fontVariation);
          if (bgY || bgX || bgScale) {
            editTransform(bgX, bgY, bgScale);
            rerenderBg();
          }

          if (uploadY || uploadX || uploadScale) {
            editUploadTransform(uploadX, uploadY, uploadScale);
            rerenderBg();
          }
          //TODO fix this when implementing template locks
          updateTextareas(titles.main, 'title');
        }
      }
    }

    fetchParams();
  }, [])

  useEffect( () => {
    const getPalettes = async () => {
      try {
        const krakenPalettes = await API.getPalettes();
        editPalettes(krakenPalettes);
      } catch (error) {
        console.error(error);
      }
    }
    getPalettes();
  }, [])


  // useEffect( () => {
  //   const { x, y, scale } = bgData[bgOption] || DEFAULT_BACKGROUND_DATA ;
  //   editTransform(x, y, scale);
  //   rerenderBg();
  // }, [])


  useEffect( () => {
    templates[channel.value].forEach(t => { addVisible(t.id) })
  }, [])



  const exportImages = () => {
    const nodes = document.querySelectorAll(".template");
    exportLayouts([...nodes], product.value, channel.value);
  }

  useEffect(() => {
    if (location.pathname === '/') return;
    if (location.hash) return;
    const pathParts = location.pathname.split('/');
    if (pathParts.length < 1) return;

    const channelValue = pathParts[1];
    const channelObj = CHANNELS.find(ch => ch.value === channelValue);
    const channelOption = { value: channelValue, label: channelObj.label };
    editChannel(channelOption);

    if (pathParts.length > 2) {
      const productValue = pathParts[2];
      const productOption = PRODUCTS.find(x => x.value === productValue);
      editProduct(productOption);
    } else {
      const productValue = channelObj.products[0];
      const productOption = PRODUCTS.find(x => x.value === productValue);
      editProduct(productOption);
    }

    const allTypes = channelObj.types;
    if (allTypes.length > 0) {
      const {label, value} = allTypes[0];
      editType({ label, value })
    } else {
      editType(GENERAL_TYPE);
    }
  }, [location]);



  const saveScene = async () => {
    const scene = {
      product,
      channel,
      type,
      titles,
      dates,
      positions,
      names,
      productNames,
      fontVariation,
      bgX,
      bgY,
      bgScale,
      uploadX,
      uploadY,
      uploadScale,
      bgOption,
      customBg,
      layoutOption,
      images,
    }

    const hash = await API.sendParams(scene);
    const url = `${window.location.origin}${location.pathname}#${hash}`
    navigator.clipboard.writeText(url);
    console.log('saving', hash);
  }


  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <main>
          <Templates />
          <Toolbar
            handleExport={exportImages}
            handleSaveScene={saveScene}
          />
        </main>
      </div>
    </ThemeProvider>
  );
}

export default App;
