import React from "react";
import "./index.css";
import Textarea from "../../../layout/Textarea/Textarea";
import {logoNorm, logoIDE, UI} from "../../../data/constants";
import { checkIfIDE } from "../../../libs/tools";
import BgTransformer from "../../../layout/BgTransformer";
import {useSetting} from "../../../contexts/setting";
import {useEdits} from "../../../contexts/edits";
import {useImages} from "../../../contexts/images";
import cn from "classnames";
import Icon from "../../../layout/Icon";
import ImageUpload from "../../../layout/ImageUpload";

const YouTubeCommunity = ({id}) => {
  const { product, layoutOption, iconOption, locked, visible } = useSetting();
  const { bgUrl } = useImages();
  const { titles, subtitles, editTitles, editSubtitles } = useEdits();
  const logoURL = checkIfIDE(product.value) ? logoIDE : logoNorm;
  const title = titles[id] || titles.main;
  const subtitle = subtitles[id] || subtitles.main;
  const iconUrl = `${UI}/Icons/${iconOption}.svg`
  const templateClass = cn("template", `layout-${layoutOption}`);
  // const titleClass = cn("title", `layout-${layoutOption}`);
  // const subtitleClass = cn("subtitle", `layout-${layoutOption}`);
  // const contentClass = cn("content", `layout-${layoutOption}`);
  // const uploadClass = cn("upload-wrap", `layout-${layoutOption}`);
  const logoClass = cn("logo", {"ide": checkIfIDE(product.value)});
  const isLocked = locked.some(elem => elem === id);
  const isVisible = visible.some(elem => elem === id);

  return (
    <>

      <div id={id} className={templateClass}>
        <div>
          <BgTransformer src={bgUrl} id={id} />

          <div className="content">
            <div className="title-wrap">
              <h1 className="title">
                <Textarea handleTextChange={value => editTitles(value, id, isLocked)} lines="2" text={title}/>
              </h1>
              {[3, 4].includes(layoutOption) && (
                <h2 className="subtitle">
                  <Textarea handleTextChange={value => editSubtitles(value, id, isLocked)} lines="1" text={subtitle}/>
                </h2>
              )}
            </div>
            {layoutOption === 7 && (<div className="icon-wrap">
              <Icon bgUrl={iconUrl}/>
            </div>)}

            <header className="template-header">
              <div className={logoClass}>
                <img src={logoURL} alt="background"/>
              </div>
              {product.value === "JB" ? "" : (
                <div className="product-name">
                  {product.label}
                </div>
              )}
            </header>
            {[5, 6].includes(layoutOption) && (<div className="upload-wrap">
              <ImageUpload id={id}/>
            </div>)}
          </div>
        </div>
      </div>
    </>
  );
}

export default YouTubeCommunity;