export const DEFAULT_PRODUCT = { label : "IntelliJ IDEA", value : "IJ" };

export const options = [
  { label: "Social Media & Blog", value: "social" },
];

export const KRAKEN_URL = "https://d3r50642m20jlp.cloudfront.net/kraken/tiles";

export const NEUROBAKER = `${KRAKEN_URL}/Neurobaker`

export const UPLOAD_ICON_URL = `${NEUROBAKER}/UI/upload.svg`;

export const EYE_FALSE = `${NEUROBAKER}/UI/Icons/eye-false.svg`;
export const EYE_TRUE = `${NEUROBAKER}/UI/Icons/eye-true.svg`;
export const DOWNLOAD_ICON_URL = `${NEUROBAKER}/UI/download.svg`;

export const GENERAL_BG = `${NEUROBAKER}/Backgrounds/social/general/1`;
export const BACKGROUNDS = `${NEUROBAKER}/Backgrounds`;

export const UI = `${NEUROBAKER}/UI`;
export const logoNorm = `${NEUROBAKER}/Logos/JETBRAINS_logo_white.svg`;
export const logoIDE = `${NEUROBAKER}/Logos/JETBRAINS_IDEs_logo_white.svg`;
export const KRAKEN_COLORS = {
  QA: "Aqua",
  // "Arend",
  BDT: "BigDataTools",
  CL: "CLion",
  CWM: "CodeWithMe",
  DG: "DataGrip",
  DS: "DataSpell",
  DL: "Datalore",
  // "Deft",
  // "Dekaf",
  // "Draft",
  EDU: "EduProduct",
  FL: "Fleet",
  // "Gateway",
  GO: "GoLand",
  GR: "Grazie",
  HUB: "Hub",
  // "IdeaVim",
  IJ: "IntellijIDEA",
  // "IntellijIDEACommunity",
  // "IntellijIDEAEdu",
  // "IntellijIDEAUltimate",
  // "IntellijRust",
  // "JBAcademy",
  JB: "JetBrains",
  KT: "Kotlin",
  // "Ktor",
  // "LicenseVault",
  MPS: "MPS",
  // "Marketplace",
  // "Mono",
  PS: "PHPStorm",
  // "PiDay",
  // "Projector",
  PC: "PyCharm",
  // "PyCharmCommunity",
  // "PyCharmDataScience",
  // "PyCharmEdu",
  QT: "QATools",
  QD: "Qodana",
  // "RPlugin",
  RS: "ReSharper",
  RScpp: "ReSharperCpp",
  // "RescUi",
  RD: "Rider",
  // "RiderFlow",
  // "RingUi",
  RM: "RubyMine",
  // "RustRover",
  SP: "Space",
  TC: "TeamCity",
  // "TestIDE",
  TB: "Toolbox",
  WS: "WebStorm",
  // "WritersIDE",
  // "Xodus",
  // "XodusDNQ",
  YT: "YouTrack",
  DC: "dotCover",
  DM: "dotMemory",
  DP: "dotPeek",
  DT: "dotTrace",
  // "dotUltimate",
}


export const fontSizes = [
  {label: "-2", value: 0.8},
  {label: "-1", value: 0.9},
  {label: "standard font size", value: 1},
  {label: "+1", value: 1.1},
  {label: "+2", value: 1.2},
  // {label: "giant", value: 2},
]


export const IDEs = [
  "QA",
  "CL",
  "DG",
  "FL",
  "IJ",
  "PC",
  "RM",
  "WS",
  "DS",
  "GO",
  "PS",
  "RD",
  "RR",
  "WR",
]

export const ICONS = [
  "key",
  "book",
  "diamond",
  "java",
  "track",
  "lamp",
]


export const TAGS = [
  {
    value: "soon",
    label: "Coming soon",
  },
  {
    value: "outdated",
    label: "Outdated",
  },
  {
    value: "latest",
    label: "Latest",
  },
]

export const TEXT_CLASSNAMES = ["title", "subtitle"]


export const PLACEHOLDERS =
  {
    "en": {
      "title": "Type your own text here",
      "date": "Date",
      "subtitle": "And here type something else",
      "position": "Position",
      "episode": "Links",
      "secondLine": "Links",
      "thirdLine": "Links"
    },
    "de": {
      "title": "Schreiben Sie hier Ihren eigenen Text",
      "date": "Datum",
      "subtitle": "Und hier schreiben Sie etwas anderes",
      "position": "Position",
      "episode": "Links",
      "secondLine": "Links",
      "thirdLine": "Links"
    },
    "fr": {
      "title": "Écrivez votre propre texte ici",
      "date": "Date",
      "subtitle": "Et ici écrivez quelque chose d'autre",
      "position": "Position",
      "episode": "Liens",
      "secondLine": "Liens",
      "thirdLine": "Liens"
    },
    "es": {
      "title": "Escribe tu propio texto aquí",
      "date": "Fecha",
      "subtitle": "Y aquí escribe algo más",
      "position": "Posición",
      "episode": "Enlaces",
      "secondLine": "Enlaces",
      "thirdLine": "Enlaces"
    },
    "br": {
      "title": "Escreva seu próprio texto aqui",
      "date": "Data",
      "subtitle": "E aqui escreva algo mais",
      "position": "Posição",
      "episode": "Links",
      "secondLine": "Links",
      "thirdLine": "Links"
    },
    "jp": {
      "title": "ここに自分のテキストを書いてください",
      "date": "日付",
      "subtitle": "そしてここに何か他のことを書いてください",
      "position": "位置",
      "episode": "リンク",
      "secondLine": "リンク",
      "thirdLine": "リンク"
    },
    "ch": {
      "title": "在这里写下你自己的文本",
      "date": "日期",
      "subtitle": "然后在这里写点别的",
      "position": "职位",
      "episode": "链接",
      "secondLine": "链接",
      "thirdLine": "链接"
    },
    "kr": {
      "title": "여기에 자신의 텍스트를 작성하세요",
      "date": "날짜",
      "subtitle": "그리고 여기에 다른 것을 쓰세요",
      "position": "직위",
      "episode": "링크",
      "secondLine": "링크",
      "thirdLine": "링크"
    },
    "tr": {
      "title": "Kendi metnini buraya yaz",
      "date": "Tarih",
      "subtitle": "Ve buraya başka bir şey yaz",
      "position": "Pozisyon",
      "episode": "Bağlantılar",
      "secondLine": "Bağlantılar",
      "thirdLine": "Bağlantılar"
    },
    "ru": {
      "title": "Напишите свой текст здесь",
      "date": "Дата",
      "subtitle": "И здесь напишите что-нибудь ещё",
      "position": "Позиция",
      "episode": "Ссылки",
      "secondLine": "Ссылки",
      "thirdLine": "Ссылки"
    }
  };
